import React from 'react'

export default () => (
  <section id="home">
    <header className="major">
      <h1>Michael <span className="accent">Nelson</span></h1>
      <p>Lead Software Engineer | Prosper, TX <br />
        <a href="mailto:me@michaelwnelson.com">me@michaelwnelson.com</a>
      </p>
    </header>
  </section>
);
